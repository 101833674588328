.table {
    width: 100%;
    border-collapse: collapse;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.table td,
.table th {
    padding: 8px;
}

tbody {
    background-color: var(--light-violet);
}

.table td {
    cursor: pointer;
    word-wrap: anywhere;
    padding: 15px 8px;
}

.table thead {
    border-radius: 15px;
}

.table thead tr th {
    padding: 12.5px 8px;
    font-weight: 600;
}

.table tr:nth-child(even) {
    background-color: var(--violet);
}

.table tr {
    border-bottom: 1px solid var(--primary-color);
}

.table tr:hover {
    background-color: var(--hover-violet);
}

.table tr:hover button:hover {
    background-color: var(--blue-dark);
}

.table th {
    text-align: left;
    background-color: var(--primary-color);
    color: white;
    position: sticky;
    top: 0;
}

.empty {
    display: flex;
    justify-content: center;
    font-size: large;
    padding: 15px;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 20px;
    padding-bottom: 5px;
}

.pagination p {
    color: var(--white);
}

.pagination input {
    width: 75px !important;
}

.pagination svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 100%;
    fill: white;
}

.pagination svg:hover {
    background: var(--background-blue);
}
