.container {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    position: relative;

    background-size: contain;
    background-position: center;
    margin-right: 10px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.type {
    margin: 0;
    position: absolute;
    top: 5px;
    left: 5px;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    padding: 4px 6px;
    border-radius: 3px;
    font-size: 0.75em;
}

.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    fill: white;
    width: 30px;
    height: 30px;
}

.deleteIcon {
    position: absolute;
    right: 5px;
    top: 5px;
    background: var(--red);
    fill: white;
    border-radius: 100%;
    cursor: pointer;
}

.previewModalContent img,
.previewModalContent audio,
.previewModalContent video {
    width: 100%;
}
