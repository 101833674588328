.tabList {
    border-bottom: 1px solid var(--links);
    padding: 0 10px;
}

.tabLabel {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 1.2rem;
    border: solid transparent;
    border-width: 1px 1px 0 1px;
    cursor: pointer;
}

.tabLabelActive {
    background: var(--background);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-color: var(--links);
    cursor: auto;
}
