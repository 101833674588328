.container {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.wrapper {
    display: table-cell;
    vertical-align: middle;
}

.header {
    display: grid;
    grid-template-columns: 1fr auto;
    place-items: center start;
    width: 100%;
    background: var(--primary-color);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    padding: 10px 20px;
}

.close {
    cursor: pointer;
}

.body {
    background: var(--background-blue);
    padding: 15px 20px;
    max-height: 90vh;
    overflow-y: auto;
}
