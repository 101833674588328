:root {
    --primary-color: #353c56;
    --primary-color-dark: #292f45;
    --gray: #9b9b9b;
    --red: #e6423c;
    --red-dark: #b9202f;
    --green: #0f862d;
    --green-dark: #0d7226;
    --orange: #e67112;
    --orange-dark: #c55900;
    --blue: #129fe6;
    --blue-dark: #0069c5;
    --background: #151d30;
    --background-darker: #292f45;
    --background-blue: #161d30;
    --background-light: #556275;
    --background-blue-darker: #18202c;
    --links: #4990f3;
    --blue-strong: #65a0f1;
    --hover-violet: #303658;
    --light-violet: #292f45;
    --violet: #2c3349;
}

* {
    box-sizing: border-box;
    font-family: 'Open Sans';
}

body {
    background: var(--background);
    color: white;
}

a {
    color: var(--links) !important;
    text-decoration: none;
}

.fake-link {
    color: var(--links) !important;
    cursor: pointer;
}

section {
    width: 95%;
    max-width: 1200px;
    margin: 30px auto;
}
section strong {
    color: var(--blue-strong);
}

h1,
h3 {
    font-weight: normal;
}

h2 {
    text-align: center;
    font-weight: 700;
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/Poppins-400.woff2') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: url('../public/fonts/Poppins-500.ttf') format('ttf');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url('../public/fonts/Poppins-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/Poppins-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    src: url('../public/fonts/Poppins-800.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: url('../public/fonts/OpenSans-300.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/OpenSans-400.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url('../public/fonts/OpenSans-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/OpenSans-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    src: url('../public/fonts/OpenSans-800.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: italic;
    src: url('../public/fonts/OpenSans-italic.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: url('../public/fonts/Inter-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: url('../public/fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url('../public/fonts/Inter-SemiBold.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/Inter-Bold.woff2') format('woff2');
}
